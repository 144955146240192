var Block = {
	top : 0,
	show: 0,
	id : 0
}
var blocks = [];
var common = {
	init: function(){
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		this.resize();
		this.nav();
		this.scrl();
		slide.init();
		setTimeout(function(){
			$body.removeClass("disable");
		},400);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).on("scroll", function(){
			common.scrl();
		});
	},
	nav: function(){
		$("h1 a").off().on("click", function(){
			var $html 	= $("html,body"),
				ease 	= "easeInOutCubic";
			
			$html.animate({
				scrollTop : "0px"
			},{
				"duration" 	: 1000,
				"easing" 	: ease
			});
		})
	},
	resize: function(){
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		if($body.hasClass("resize")){
			$body.removeClass("resize");
		}
		common.checkBlock();
	},
	checkBlock: function(){
		blocks = [];
		$(".block").each(function(i){
			var _b = object(Block);
			_b.top = $(this).offset().top;
			_b.show = _b.top - $(window).height()*.3;
			blocks.push(_b);
		});
	},
	scrl: function(){
		var topD = $(document).scrollTop(),
			endD = topD + $(window).height(),
			$body = $("body"),
			len 	= blocks.length;
		if(topD >= blocks[1].top){
			if(!$body.hasClass("fix")){
				$body.addClass("fix");
			}
		}else{
			if($body.hasClass("fix")){
				$body.removeClass("fix");
			}
		}
		for(var i = 0; i < len; i++){
			if(i == len - 1){
				if(endD >= blocks[i].show){
					addAnimation(i)
				}
			}else{
				if(endD >= blocks[i].show && endD <= blocks[i+1].show){
					addAnimation(i)
				}
			}
		}
		function addAnimation(j){
			var $blk = $(".block").eq(j);
			//removeAnimation();
			if(!$blk.hasClass("animate")){
				//removeotherAnimation(j);
				$blk.addClass("animate");
			}
		}
	}
}
$(function(){
	common.init();
});