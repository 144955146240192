var slideTimer;
var slide = {
	count: 0,
	max : 4,
	init: function(){
		slide.count = 0;
		this.nav();
		clearInterval(slideTimer);
		slideTimer = setInterval(function(){
			slide.increase();
		},5000);
	},
	increase: function(){
		slide.count++;
		if(slide.count >= slide.max){
			slide.count = 0;
		}
		this.change();
	},
	nav: function(){
		$("#btn_next").off().on("click", function(){
			slide.count++;
			if(slide.count >= slide.max){
				slide.count = 0;
			}
			clearInterval(slideTimer);
			slide.change();
		});
		$("#btn_prev").off().on("click", function(){
			slide.count--;
			if(slide.count < 0){
				slide.count = slide.max-1;
			}
			clearInterval(slideTimer);
			slide.change();
		});
		$(".slide_nav a").off().on("click", function(){
			clearInterval(slideTimer);
			slide.count = $(".slide_nav a").index(this);
			slide.change();
		});
		$(".slide_nav_pc a").off().on("click", function(){
			clearInterval(slideTimer);
			slide.count = $(".slide_nav_pc a").index(this);
			slide.change();
		});
		const limit = 70;
		const limitX = 70;
		var lastTouchX = lastTouchX || 0,
			position,touching,cx,touchXDelta;
		if(isSP || isIPad){
			$(".imgs").off().on({
				'touchstart': function(e) {
					position = getPosition(e);
					cx = 0;
					lastTouchX = event.touches[0].clientX;
				},
				'touchmove': function(e) {
					var touchX = event.touches[0].clientX,
						touchXDelta = touchX - lastTouchX;
					cx = position - getPositionX(event);
					// console.log('[isScrollingUp]touchYDelta: ' + touchYDelta);
					lastTouchX = touchX;
				},
				'touchend': function(e) {
					if(cx < -limitX){
						slide.count--;
						if(slide.count < 0){
							slide.count = slide.max-1;
						}
						slide.change();
					}else if(cx > limitX){
						slide.count++;
						if(slide.count >= slide.max){
							slide.count = 0;
						}
						slide.change();
					}
					cx = 0;
				},
				'touchcancel': function(e){
				}
			});
			function getPosition(event){
				return event.touches[0].pageX;
			}
			function getPositionX(event){
				return event.touches[0].pageX;
			}
		}
	},
	change: function(){
		var cls = "active" + slide.count;
		$(".slide_nav a").removeClass("active");
		$(".slide_nav_pc a").removeClass("active");
		$(".slide.sp").removeClass(function(index, className) {
			return (className.match(/\bactive\S+/g) || []).join(' ');
		});
		$(".col2.pc").removeClass(function(index, className) {
			return (className.match(/\bactive\S+/g) || []).join(' ');
		});
		$(".slide.sp").addClass(cls);
		$(".col2.pc").addClass(cls);
		$(".slide_nav a").eq(slide.count).addClass("active");
		$(".slide_nav_pc a").eq(slide.count).addClass("active");
		clearInterval(slideTimer);
		slideTimer = setInterval(function(){
			slide.increase();
		},5000);
	}
}